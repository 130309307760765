import AboutUs from '../components/hompage/AboutUs';
import Community from '../components/hompage/Community';
import Hero from '../components/hompage/Hero';
import Navbar from '../components/hompage/Navbar';
import Roadmap from '../components/hompage/Roadmap';

export default function Homepage() {
  return (
    <div>
      <Navbar></Navbar>
      <div className='relative w-full justify-center items-center flex'>
        <div className='opacity-5 absolute'>
          <img
            src='/assets/bgs/hero-bg.png'
            alt=''
          />
        </div>
        <div className=''>
          <Hero></Hero>
          <Community></Community>
        </div>
      </div>
      <div className='relative w-full justify-center items-center flex'>
        <div className='opacity-5 absolute'>
          <img
            src='/assets/bgs/hero-bg.png'
            alt=''
          />
        </div>
        <div className=''>
          <Roadmap></Roadmap>
          <AboutUs></AboutUs>
        </div>
      </div>
    </div>
  );
}
