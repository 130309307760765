import Container from '../Container';

export default function Navbar() {
  return (
    <Container>
      <div className='mt-5 flex justify-between items-center'>
        <div className=''>
          <img
            src='/assets/logos/tdc-nav-logo.png'
            alt=''
          />
        </div>

        {/* <div className='flex'> */}
        <div className='font-lilita text-white underline text-xl'>
          <a href='#community'>Community</a>
        </div>
        <div className='font-lilita text-white underline text-xl'>
          {' '}
          <a href='#roadmap'>Roadmap</a>
        </div>
        <div className='font-lilita text-white underline text-xl'>
          {' '}
          <a href='#aboutus'>About Us</a>
        </div>
        {/* </div> */}
        <div></div>
        <div></div>
      </div>
    </Container>
  );
}
