import Container from '../Container';

export default function Community() {
  const handleDivClick = (link: string) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };
  return (
    <section id='community'>
      <Container className=' min-h-screen flex flex-col items-center justify-center '>
        <div className='flex items-center bg-slate-800 p-10 rounded-3xl'>
          <div className='flex-1'>
            <img
              src='/assets/images/community-hug.png'
              alt=''
            />
          </div>
          <div className='flex flex-col flex-1'>
            <div className='flex'>
              <div className='font-lilita text-white text-5xl pr-2 items-center'>
                Join The Damily
              </div>
              <div className='size-12'>
                <img
                  src='/assets/logos/logo.png'
                  alt=''
                />
              </div>
            </div>
            <div className='p-5'></div>
            <p className='font-comic text-md text-xl'>
              <span className='text-white'>Welcome to </span>{' '}
              <span className='text-primary'>“THE DANK CLUB” </span>
              <span className='text-white'>
                . The ultimate community where memes reign supreme, creativity
                flows, and good vibes never end. Whether you’re here to share
                hilarious content, discover the latest trends, or just chill
                with like-minded legends, this is the place for you. Join us to
                connect, laugh, and make unforgettable moments with a community
                that gets it. No filters, just pure dankness.
              </span>
            </p>
            <div className='flex mt-10 '>
              <button
                onClick={() => handleDivClick('https://x.com/thedankclub')}
                className='rounded-full p-4 mx-5 bg-white size-20 z-10'
              >
                <img
                  src='/assets/socials/x.png'
                  alt=''
                />
              </button>

              <button
                onClick={() => handleDivClick('https://x.com/thedankclub')}
                className='rounded-full p-4 mx-5 bg-white size-20 z-10'
              >
                <img
                  src='/assets/socials/discord.png'
                  alt=''
                />
              </button>

              <button
                onClick={() => handleDivClick('https://t.me/thedank_club')}
                className='rounded-full p-4 mx-5 bg-white  size-20 z-10'
              >
                <img
                  src='/assets/socials/telegram.png'
                  alt=''
                />
              </button>

              <button
                onClick={() =>
                  handleDivClick('https://www.instagram.com/thedank.club/')
                }
                className='rounded-full p-4  mx-5 bg-white size-20 z-10'
              >
                <img
                  src='/assets/socials/instagram.png'
                  alt=''
                />
              </button>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
