import Container from '../Container';

export default function AboutUs() {
  return (
    <section id='aboutus'>
      <Container className=' min-h-screen flex flex-col items-center justify-center '>
        <div className='flex items-center bg-slate-800 p-10 rounded-3xl'>
          <div className='flex-1'>
            <div className='flex flex-col flex-1'>
              <div className='flex'>
                <div className='font-lilita text-white text-5xl pr-2 items-center'>
                  About - The Dank Club
                </div>
                <div className='size-12'>
                  <img
                    src='/assets/logos/tdc-logo.png'
                    alt=''
                  />
                </div>
              </div>
              <div className='p-5'></div>
            </div>
            <p className='font-comic text-md text-lg text-white text-justify'>
              Welcome to The Dank Club, where crypto meets culture! We're not
              just another blockchain project—we’re building a vibrant community
              for memers, creators, and everyone who thrives in the digital
              space. Whether you're a meme lord, crypto enthusiast, or someone
              looking for a place to connect with like-minded people, The Dank
              Club is your home.
            </p>
            <div className='flex'>
              <div className='font-comic text-md text-lg text-white flex-1 text-justify'>
                <p className='pt-5'>
                  Our mission is simple: celebrate humor, foster connections,
                  and explore the exciting possibilities of decentralized tech.
                  We believe in the power of memes to unite people, spark
                  conversations, and make the crypto world a little more fun.
                </p>

                <p className='pt-5'>
                  Join us on this journey, where community is currency and every
                  laugh is a step closer to the future we imagine. This is more
                  than a platform—it's a movement, and we’re just getting
                  started.
                </p>

                <p className='pt-5 text-primary text-2xl'>
                  Stay dank. Stay connected.
                </p>
              </div>
              <div className='mx-5 flex flex-1'>
                <img
                  src='/assets/images/aboutus-alien.png'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
