import Container from '../Container';

export default function Hero() {
  return (
    <section id='hero'>
      <Container className='max-sm:min-h-full flex flex-col items-center my-12 max-sm:pt-28'>
        <div className='flex items-center'>
          <div className='flex flex-col flex-1'>
            <div className='font-lilita text-white text-8xl'>Dankers being</div>
            <div className='font-lilita text-white text-8xl'>Dankers</div>
            <div className='p-10'></div>
            <div className='font-comic text-primary text-2xl'>
              Dankers Community backed by CRYPTO
            </div>
          </div>
          <div className='flex-1'>
            <img
              src='/assets/logos/tdc-logo.png'
              alt=''
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
