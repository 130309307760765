import React from 'react';
import logo from './logo.svg';
import './App.css';
import Homepage from './pages/Homepage';

function App() {
  return <Homepage></Homepage>;
}

export default App;
