import Container from '../Container';

export default function Roadmap() {
  return (
    <section id='roadmap'>
      <Container className='min-h-screen flex flex-col justify-center '>
        <div className='flex items-end'>
          <div className='font-lilita text-white text-6xl pr-2 items-center justify '>
            Roadmap
          </div>
          <div className='size-12'>
            <img
              src='/assets/images/roadmap-rocket.png'
              alt=''
            />
          </div>
        </div>
        <p className='font-comic text-md text-xl text-white text-justify mt-2'>
          We're just getting started on this exciting journey! 🚀 The Dank Club
          is in its early stages, and we're hard at work building the foundation
          for something incredible. Our roadmap is in progress.
        </p>
        <div className='mt-20'>
          <img
            src='/assets/images/roadmap.png'
            alt=''
          />
        </div>
      </Container>
    </section>
  );
}
